<template>
  <div class="">
      <el-button size="small" type="primary" @click="openAdd">添加门店</el-button>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >

  <template #status={row}>
     <span v-if="row.status == 1" style="color: #23c3c4">待审核</span>
              <span v-else-if="row.status == 2" style="color: #b8b8b8">已通过</span>
              <span v-else-if="row.status == 3" style="color: #ff6262">已拒绝</span>
              <span v-else-if="row.status == 4" style="color: #ff6262">注销</span>
              <span v-else-if="row.status == 5" style="color: #ff6262">冻结</span>
    </template>
    <template #handle={row}>
      <el-button size="small" type="primary" @click="toDetail(row)">详情</el-button>
    </template>

    </auto-table>

    <editStore ref="edit" @success="getList(1)"></editStore>
  </div>
</template>

<script>
import editStore from './editStore.vue';
export default {
    components:{
        editStore
    },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "机构名称", value: "mechanism_name" },
        { name: "门店类别", value: "categories_child" },
        { name: "负责人姓名", value: "contacts" },
         { name: "负责人电话", value: "contact_telephone" },
        { name: "状态", value: "status",type: "custom"  },
        { name: "操作", value: "handle", type: "custom" },
      ],
      Total: 0,
    };
  },

  mounted() {
    this.cooperator_id = this.$route.query.id;
  },

  methods: {
    openAdd(){
        this.$refs.edit.open()
    },
    load() {
      if (this.DataList.length == 0) {
        this.getList(1);
      }
    },
    toDetail(row){
        this.$router.push({ name: "mecDetails", query: { mecID: row.id } });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/mastermechanism/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          cooperator_id:this.cooperator_id
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>