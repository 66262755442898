<template>
  <div class="">
     <el-form :inline="true" >
  <el-form-item label="收益类型">
    <el-select v-model="type" placeholder="选择收益类型" clearable>
      <el-option label="课程收益" :value="1"></el-option>
      <el-option label="会员卡收益" :value="3"></el-option>
       <el-option label="消费金收益" :value="4"></el-option>
         <el-option label="支出" :value="2"></el-option>
          <el-option label="其他收入" :value="5"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item>
     <el-button  type="primary" @click="getList(1)">搜索</el-button>
  </el-form-item>
</el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
       <template #type="{ row }">
            <div>
              {{ row.pm ? "收益" : "支出" }}
            </div>
          </template>
            <template #status="{ row }">
            <div>
             <el-tag type="success" size="small" v-if="row.status === 1">已到账</el-tag>
             <el-tag type="info" size="small" v-if="row.status === 2">未到账</el-tag>
             <el-tag type="warning" size="small" v-if="row.status === 3">已退款</el-tag>
            </div>
          </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  props: ["cooperator_id"],
  data() {
    return {
      DataList: [],
      Option: [
        { name: "机构", value: "mechanism_name" },
        { name: "金额(元)", value: "cash" },
        { name: "类型", value: "type", type: "custom" },
        { name: "状态", value: "status", type: "custom" },
        { name: "时间", value: "create_time" },
        { name: "说明", value: "remark" },
      ],
      Total: 0,
      type:''
    };
  },

  mounted() {
    
  },

  methods: {
    load() {
      if (this.DataList.length == 0) {
        this.getList(1);
      }
    },
    userDetail(row) {
      this.$router.push({ name: "userDetails", params: { id: row.user_id } });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/adminBonusLog/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          cooperator_id: this.cooperator_id,
          type:this.type || null
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>