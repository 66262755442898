<template>
  <div class="">
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
        <template #type="{ row }">
        <div>{{ type[row.type] }}</div>
      </template>
      <template #handle="{ row }">
        <el-button size="small" type="primary" @click="details(row)"
          >物业详情</el-button
        >
      </template>
    </auto-table>
  </div>
</template>

<script>
export default {
     props: ["cooperator_id"],
  data() {
    return {
      type: {
        cooperator: "合作商",
        property: "物业",
      },
      DataList: [],
      Option: [
        { name: "合作商", value: "title" },
        { name: "联系人", value: "name" },
        { name: "联系电话", value: "phone" },
        { name: "类型", value: "type", type: "custom" },
        { name: "城市", value: "city" },
        { name: "创建时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom" },
      ],
      Total: 0,
    };
  },

  mounted() {},

  methods: {
    load() {
      if (this.DataList.length == 0) {
        this.getList(1);
      }
    },
    details(row) {
      this.$router.push({ name: "cooperatorDetails", query: { id: row.id } });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/cooperator/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          type: "property",
          parent_id: this.cooperator_id,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>