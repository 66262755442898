<template>
  <!-- 门店新增，修改 -->
  <div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
      <el-form label-width="120px" ref="form">
        <el-row>
          <el-col :span="12">
            <el-form-item label="门店名称" required>
              <el-input
                v-model="form.mechanism_name"
                placeholder="门店名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人" required>
              <el-input
                v-model="form.contacts"
                placeholder="联系人名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人电话" required>
              <el-input
                v-model="form.mechanism_telephone"
                placeholder="联系人电话"
              ></el-input>
            </el-form-item>
            <el-form-item label="登录账号" prop="login_name" v-if="!form.id">
              <el-input
                v-model="form.contact_telephone"
                placeholder="账号"
              ></el-input>
            </el-form-item>
            密码123456
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择地址">
              <addr-select
                @getform="changeMapAddr"
                :addr="{
                  province: form.province,
                  city: form.city,
                  district: form.district,
                }"
              ></addr-select>
            </el-form-item>
            <el-form-item label="省市区" required>
              <city-select @change="changeCity" :temp="cityList"></city-select>
            </el-form-item>
            <el-form-item label="详细地址" required>
              <el-input
                v-model="form.mechanism_addr"
                placeholder="请输入地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="介绍">
              <el-input
                type="textarea"
                v-model="form.introduction_content"
                placeholder="门店介绍"
              ></el-input>
            </el-form-item>
            <el-form-item label="介绍图片">
              <images-uploader
                ref="imgs"
                @getImgUrl="getProductImg"
                :urls="temp.introduction_pic"
              >
              </images-uploader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="form.id"
          type="primary"
          @click="updateForm"
          :loading="loading"
          >更 新</el-button
        >
        <el-button v-else type="primary" :loading="loading" @click="addForm"
          >新 增</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import citySelect from "@/components/select/citySelect.vue";
import { ObjectChangedValue } from "@/js/utils.js";
export default {
  components: {
    citySelect,
  },
  data() {
    return {
      clear: false,
      form: {},
      temp: {},
      cityList: [],
      dialogVisible: false,
      coop_id: "",
      loading: false,
    };
  },

  mounted() {
    this.coop_id = this.$route.query.id;
  },

  methods: {
    addForm() {
      this.form.type = "store";
      this.form.hide = true;
      this.form.cooperator_id = this.coop_id || null;
      this.loading = true;

      this.$axios({
        url: "/user/mastermechanism/insertAppointmentMechanismInfo",
        method: "post",
        data: this.form,
      })
        .then((res) => {
          this.$notify({
            message: "新增成功",
            duration: 2000,
            type: "success",
          });
          this.dialogVisible = false;
          this.loading = false;
          this.$emit("success", 1);
        })
        .catch(() => {
          this.loading = false;
        });
    },

    updateForm() {
      const data = ObjectChangedValue(this.temp, this.form);
      if (Object.keys(data).length == 0) {
        this.$message("没有修改数据，无需更新");
        return;
      }
      this.loading = true;
      this.$axios({
        url: "/user/mastermechanism/update",
        method: "post",
        data: {
          id: this.form.id,
          ...data,
        },
      })
        .then((res) => {
          this.$notify({
            message: "更新成功",
            duration: 2000,
            type: "success",
          });
          this.dialogVisible = false;
          this.loading = false;
          this.$emit("success", 1);
        })
        .catch(() => {
          this.loading = false;
        });
    },

    open(info) {
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
        this.cityList.push(info.province);
        this.cityList.push(info.city);
        this.cityList.push(info.district);
        this.coop_id = info.parent_id;
      } else {
        this.form = {};
        this.temp = {};
        this.cityList = [];
      }
      this.dialogVisible = true;

      //   this.$refs.imgs.clearAll();
    },
    //选择地址
    changeMapAddr(addr) {
      this.$set(this.form, "province", addr.province);
      this.$set(this.form, "city", addr.city);
      this.$set(this.form, "district", addr.district);
      this.$set(this.form, "latitude", addr.addrPoint.lat);
      this.$set(this.form, "longitude", addr.addrPoint.lng);
      this.$set(this.form, "mechanism_addr", addr.address);
      this.cityList.push(addr.province);
      this.cityList.push(addr.city);
      this.cityList.push(addr.district);
    },
    changeCity(v) {
      this.$set(this.form, "province", v[0]);
      this.$set(this.form, "city", v[1]);
      this.$set(this.form, "district", v[2]);
      this.cityList = v;
    },
    getProductImg(v) {
      this.form.introduction_pic = v;
    },
  },
};
</script>

<style lang="less" scoped>
</style>