<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-button type="primary" @click="addShow = true">添加员工</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
      :loading="loading"
    >
      <template #mechanism="{ row }">
        <div v-if="row.mechanism_id > 0">
          {{ row.map.mechanism.mechanism_name }}
        </div>
        <div v-else>未设置</div>
      </template>

      <template #handle="{ row }">
        <el-button type="primary" size="small" @click="openDivied(row)"
          >设置分成</el-button
        >
        <el-button type="success" size="small" @click="openUpdate(row)"
          >修改</el-button
        >
        <el-button type="primary" size="small" @click="openFloor(row)"
          >分配楼栋</el-button
        >
        <el-button type="danger" size="small" @click="delStaff(row)"
          >删除员工</el-button
        >
      </template>
    </auto-table>

    <!-- 添加员工 -->
    <el-dialog title="添加员工" :visible.sync="addShow" width="30%">
      <el-form
        :model="addForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item label="员工姓名" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="员工电话" prop="phone">
          <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="所属社区" required>
          <mechanism-select
            :condition="{ is_community: true, cooperator_id: cooperator_id }"
            :mecid.sync="mechanism_id"
          ></mechanism-select>
        </el-form-item>
        <el-form-item label="员工职位" prop="type">
          <el-radio v-model="addForm.type" label="staff">员工</el-radio>
          <el-radio v-model="addForm.type" label="staffManager">经理</el-radio>
          <el-radio v-model="addForm.type" label="staffKeeper">管家</el-radio>
        </el-form-item>
      </el-form>
      <span>员工使用电话号码登录，默认密码123456</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addShow = false">取 消</el-button>
        <el-button type="primary" @click="addStaff">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 设置分成 -->
    <el-dialog title="设置分成" :visible.sync="diviedShow" width="30%">
      <el-form label-width="100px">
        <el-form-item label="员工">
          {{ curBonus.name }}({{curBonus.position}})
        </el-form-item>
        <el-form-item label="会员卡分成">
          <div class="flex"><el-input v-model="member"></el-input> %</div>
            <p>
            邀请购买会员卡可分成比例<span class="color"
              >{{ ((1 - divided.member - divided.member_active) * 100).toFixed(0) }}%</span
            >
            <span v-if="divided.member > 0">，经理已分成{{(divided.member * 100).toFixed(0)}}%</span>
            <span v-if="divided.member_active > 0">，激活已分成{{(divided.member_active * 100).toFixed(0)}}%</span>
          </p>
        </el-form-item>
        <el-form-item label="消费金分成">
          <div class="flex"><el-input v-model="consume"></el-input> %</div>
            <p>
            邀请购买消费金可分成比例<span class="color"
              >{{ ((1 - divided.consume - divided.consume_active) * 100).toFixed(0) }}%</span
            >
            <span v-if="divided.consume > 0">，经理已分成{{(divided.consume * 100).toFixed(0)}}%</span>
           <span v-if="divided.consume_active > 0">，激活已分成{{(divided.consume_active * 100).toFixed(0)}}%</span>
          </p>
           
        </el-form-item>
        <el-form-item label="课程分成">
          <div class="flex"><el-input v-model="course"></el-input> %</div>
          <p>
            楼栋用户课程可分成比例<span class="color"
              >{{ ((1 - divided.course - divided.course_active) * 100).toFixed(0) }}%</span
            >
            <span v-if="divided.course > 0">，经理已分成{{(divided.course * 100).toFixed(0)}}%</span>
           <span v-if="divided.course_active > 0">，激活已分成{{(divided.course_active * 100).toFixed(0)}}%</span>
          </p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="diviedShow = false">取 消</el-button>
        <el-button type="primary" :loading="d_load" @click="submitDiviForm"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 修改员工 -->
    <el-dialog title="修改员工" :visible.sync="updateShow" width="30%">
      <el-form ref="ruleForm" label-width="100px">
        <el-form-item label="员工姓名" prop="name">
          {{ updateInfo.name }}
        </el-form-item>
        <el-form-item label="员工电话" prop="phone">
          {{ updateInfo.phone }}
        </el-form-item>
        <el-form-item label="所属社区" required>
          <mechanism-select
            ref="mec"
            :placeholder="updateInfo.mechanism_id"
            :condition="{ is_community: true, cooperator_id: cooperator_id }"
            :mecid.sync="updateInfo.mechanism_id"
          ></mechanism-select>
        </el-form-item>
        <el-form-item label="员工职位" prop="type">
          <el-radio v-model="updateInfo.position" label="员工">员工</el-radio>
          <el-radio v-model="updateInfo.position" label="经理">经理</el-radio>
          <el-radio v-model="updateInfo.position" label="管家">管家</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateShow = false">取 消</el-button>
        <el-button type="primary" @click="updateStaff">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="设置楼栋" :visible.sync="floorShow" width="50%">
      <el-form ref="ruleForm" label-width="100px">
        <el-form-item label="员工管理楼栋" prop="name">
          <el-tag
            v-for="tag in staffFloor"
            :key="tag.id"
            @close="delStaffFloor(tag)"
            closable
          >
            {{ tag.floor_no }}
          </el-tag>
        </el-form-item>
        <el-form-item label="分配楼栋" prop="phone">
          <el-checkbox-group v-model="addFloorList">
            <el-checkbox
              v-for="(floor, i) in canAddList"
              :label="floor"
              :key="i"
              >{{ floor }}</el-checkbox
            >
          </el-checkbox-group>
          <el-button type="primary" @click="addStaffFloor">分 配</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="floorShow = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
export default {
  name: "staff",
  props: ["cooperator_id"],
  data() {
    return {
      DataList: [],
      Option: [
        { name: "姓名", value: "name" },
        { name: "社区", value: "mechanism", type: "custom" },
        { name: "职位", value: "position" },
        { name: "电话", value: "phone" },
        { name: "创建时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom" },
      ],
      Total: 0,
      addShow: false,
      addForm: {
        name: "",
        phone: "",
      },
      rules: {
        name: [{ required: true, message: "请输入员工姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请输入员工电话", trigger: "blur" }],
        type: [
          { required: true, message: "请选择员工职位", trigger: "change" },
        ],
      },
      diviedShow: false,
      curBonus: {},
      course: "",
      consume: "",
      member: "",
      divided: {
        course: 0,
        consume: 0,
        member: 0,
        course_active: 0,
        consume_active: 0,
        member_active: 0,
      },
      division: {},
      d_load: false,
      mechanism_id: "",

      updateShow: false,
      updateInfo: "",

      changeUser: {},
      floorShow: false,
      floorList: [],

      staffFloor: [],
      addFloorList: [],

      assignedFloor:[],

      manager: JSON.parse(localStorage.getItem("managerInfo")),
      loading:false
    };
  },

  mounted() {},
  computed: {
    canAddList() {
      let assignedFloor = this.assignedFloor.map((t) => t.floor_no);
      return this.floorList.filter((t) => !assignedFloor.includes(t));
    },
  },
  methods: {
    addStaffFloor() {
      let data = {
        handle_id: this.manager.id,
        handle_name: this.manager.name,
        admin_id: this.changeUser.admin_id,
        floors: this.addFloorList,
        mechanism_id: this.changeUser.mechanism_id,
      };
      this.$post("user/staffFloor/batchInsert", data).then((res) => {
        this.$alert(res.data.data).then(() => {
          this.floorShow = false;
          this.addFloorList = [];
        });
      });
    },
    delStaffFloor(tag) {
      this.$confirm("确认取消员工绑定的楼栋" + tag.floor_no).then((res) => {
        let data = {
          id: tag.id,
        };

        this.$get("user/staffFloor/delete", data).then((res) => {
          this.getStaffFloor();
        });
      });
    },
    openFloor(row) {
      this.changeUser = row;
      this.getFloorMap();
    
      this.getStaffFloor();
      this.getAssignedFloor() 
        this.floorShow = true;
    },
    //社区楼栋信息
    getFloorMap() {
      this.$get("user/dict/getMap", {
        use_id: this.changeUser.mechanism_id,
      }).then((res) => {
        if (res.data.data.floor_no) {
          this.floorList = res.data.data.floor_no;
        } else {
          this.floorList = [];
        }
      });
    },
    //机构已分配楼栋
     getAssignedFloor() {
      this.$get("user/staffFloor/query", {
        mechanism_id: this.changeUser.mechanism_id,
      }).then((res) => {
        this.assignedFloor = res.data.data;
      });
    },
    //员工绑定的楼栋
    getStaffFloor() {
      this.$get("user/staffFloor/query", {
        admin_id: this.changeUser.admin_id,
      }).then((res) => {
        this.staffFloor = res.data.data;
      });
    },
    openUpdate(row) {
      this.updateShow = true;
      this.updateInfo = row;
    },
    updateStaff() {
      let map = {
        员工: "staff",
        管家: "staffKeeper",
        经理: "staffManager",
      };
      let data = {
        id: this.updateInfo.id,
        admin_id: this.updateInfo.admin_id,
        position: this.updateInfo.position,
        mechanism_id: this.updateInfo.mechanism_id,
        type: map[this.updateInfo.position],
        cooperator_id: this.updateInfo.cooperator_id,
      };

      this.$post("user/adminBonus/updateStaff", data).then(() => {
        this.$message("成功");
        this.$refs.mec.clear();
        this.updateShow = false;
        this.getList(1);
      });
    },
    load() {
      if (this.DataList.length == 0) {
        this.getList(1);
      }
    },
    openDivied(row) {
      this.curBonus = row;
      if (row.division_id) {
        this.getDivisionInfo();
      }
       this.divided = {
        course: 0,
        consume: 0,
        member: 0,
        course_active: 0,
        consume_active: 0,
        member_active: 0,
      }
      //物业分成
      //经理分成
      this.getCoopDivid();
       this.getManagerDivid();
      this.diviedShow = true;
    },
    submitDiviForm() {
      if (this.curBonus.division_id > 0) {
        this.updateDivid();
      } else {
        this.addDivid();
      }
    },
    //添加分成记录
    addDivid() {
      if(!this.checkBonusProportion()) {return} ;
      let data = {
        course: this.toDouble(this.course),
        consume: this.toDouble(this.consume),
        member: this.toDouble(this.member),
        id: this.curBonus.id,
        cooperator_id: this.curBonus.cooperator_id,
        mechanism_id: this.curBonus.mechanism_id,
        admin_id: this.curBonus.admin_id,
      };
      this.d_load = true;
      this.$axios({
        url: "/user/adminBonus/editStaffDivision",
        method: "post",
        data,
      })
        .then((res) => {
          this.$message("成功");
          this.getList(1);
          this.diviedShow = false;
          this.d_load = false;
        })
        .catch((err) => {
          this.d_load = false;
        });
    },
    //更新分成记录
    updateDivid() {
      if(!this.checkBonusProportion()) {return} ;
      let data = {
        course: this.toDouble(this.course),
        consume: this.toDouble(this.consume),
        member: this.toDouble(this.member),
        id: this.curBonus.division_id,
      };
      this.d_load = true;
      this.$axios({
        url: "/user/divisionProportion/update",
        method: "post",
        data,
      })
        .then((res) => {
          this.$message("成功");
          this.getList(1);
          this.diviedShow = false;
          this.d_load = false;
        })
        .catch((err) => {
          this.d_load = false;
        });
    },
    //检查分成比例
    checkBonusProportion(){
         if (
        this.course >
        (1  - this.divided.course - this.divided.course_active) * 100
      ) {
        this.$message("课程剩余的可分成比例不足");
        return false;
      }
       if (
        this.consume >
        (1  - this.divided.consume - this.divided.consume_active) * 100
      ) {
        this.$message("消费金剩余的可分成比例不足");
        return false;
      }
       if (
        this.member >
        (1  - this.divided.member - this.divided.member_active) * 100
      ) {
        this.$message("会员卡剩余的可分成比例不足");
        return false;
      }
      return true
    },
    //员工自己分成信息
    getDivisionInfo() {
      this.$axios({
        url: "/user/divisionProportion/findById",
        params: {
          id: this.curBonus.division_id,
        },
      }).then((res) => {
        let v = res.data.data;
        this.division = v;
        this.course = v.course * 100;
        this.consume = v.consume * 100;
        this.member = v.member * 100;
      });
    },
    getManagerDivid(){
       this.$axios({
        url: "/user/adminBonus/queryAdminDivi",
        params: {
          cooperator_id: this.curBonus.cooperator_id,
          mechanism_id: this.curBonus.mechanism_id,
          position:'经理'
        },
      }).then((res) => {
        res.data.data.forEach(divi => {
           this.divided.course =  this.divided.course  + divi.course
           this.divided.consume =  this.divided.consume + divi.consume
           this.divided.member =  this.divided.member  + divi.member
        });
      });
    },
    //物业分成，激活员工分成
    getCoopDivid() {
      this.$axios({
        url: "/user/divisionProportion/queryDividByCoop",
        params: {
          id: this.curBonus.cooperator_id,
        },
      }).then((res) => {
        let info = res.data.data
        this.divided.consume_active = info.consume_active
        this.divided.course_active = info.course_active
        this.divided.member_active = info.member_active
      });
    },
    toDouble(v) {
      return (v / 100).toFixed(2);
    },
    delStaff(row) {
      this.$confirm("确定删除员工,删除后将取消该员工的分成").then(() => {
        this.$axios({
          url: "/user/adminBonus/delPropertyStaff",
          method: "post",
          data: {
            admin_id: row.admin_id,
            id: row.id,
          },
        }).then((res) => {
          this.$message("删除成功");
          this.getList(1);
        });
      });
    },
    addStaff() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$axios({
            url: "/user/adminBonus/insertPropertyStaff",
            method: "post",
            data: {
              name: this.addForm.name,
              phone: this.addForm.phone,
              type: this.addForm.type || "staff",
              cooperator_id: this.cooperator_id,
              mechanism_id: this.mechanism_id,
            },
          }).then((res) => {
            this.addShow = false;
            this.getList(1);
          });
        }
      });
    },

    getList(v) {
      this.loading = true
      let page = v || 1;
      this.$axios({
        url: "/user/adminBonus/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          type: "staff",
          cooperator_id: this.cooperator_id,
        },
      }).then((res) => {
         this.loading = false
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.color {
  color: rgb(238, 145, 57);
}
</style>