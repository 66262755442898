<template>
  <div class="">
    <el-button size="small" type="primary" @click="openSend"
      >合作商发卡</el-button
    >

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #user="{ row }">
        <link-to
          :to="{ name: 'userDetails', params: { id: row.get_code_user_id } }"
        >
          {{ row.map.getUserInfo.nick_name || "查看用户" }}</link-to
        >
      </template>
    </auto-table>

    <el-dialog title="合作商发卡" :visible.sync="sendShow" width="30%">
      <el-form ref="form" label-width="100px">
        <el-form-item label="卡券类型"> 海马会员卡 </el-form-item>
        <el-form-item label="会员卡" v-if="cooperator.type == 'commerce'">
          <el-select v-model="change_mamber" placeholder="请选择">
            <el-option
              v-for="item in memberList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生成方式" required>
          <el-radio v-model="send_type" label="mobiles">手动填写</el-radio>
          <el-radio v-model="send_type" label="excel">导入表格</el-radio>
        </el-form-item>
        <el-form-item label="社区机构" v-if="cooperator.type != 'commerce'">
          <mechanism-select
            :mecid.sync="community_id"
            ref="community"
            :condition="{ cooperator_id: cooperator_id }"
          ></mechanism-select>
        </el-form-item>

        <div v-if="send_type == 'mobiles'">
          <el-form-item label="发放用户" required>
            <p>请填写要发放用户的电话</p>
            <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              type="success"
              @click="showInput"
              >新增电话</el-button
            >
          </el-form-item>
        </div>
        <div v-if="send_type == 'excel'">
          <el-form-item label="发放用户" required>
            <el-upload
              action=""
              :on-change="beforeUpload"
              :file-list="fileList"
              :auto-upload="false"
              :show-file-list="true"
              accept=".xls, .xlsx"
              list-type="text"
              :limit="1"
              ref="upload"
            >
              <el-button type="success" size="small">导入表格</el-button>
              <el-button
                type="text"
                @click.stop="loadTemplate"
                style="margin: 10px"
                >下载模板</el-button
              >
              <el-popover
              v-if="cooperator.type != 'commerce'"
                placement="top-start"
                title="格式说明"
                width="400"
                trigger="hover"
              >
                <div>
                  表格数据格式：
                  <br />
                  "电话"、"业主姓名"、"区域"、楼号"、"单元"、"房号"
                  <div class="flex">
                    <div>
                      <div class="table">电话</div>
                      <div class="table">1859524*****</div>
                      <div class="table">1859524*****</div>
                    </div>
                    <div>
                      <div class="table">业主姓名</div>
                      <div class="table">张三</div>
                      <div class="table">李四</div>
                    </div>
                    <div>
                      <div class="table">区域</div>
                      <div class="table">1区</div>
                      <div class="table">A</div>
                    </div>
                    <div>
                      <div class="table">楼号</div>
                      <div class="table">2</div>
                      <div class="table">3栋</div>
                    </div>
                    <div>
                      <div class="table">单元</div>
                      <div class="table">2</div>
                      <div class="table">3</div>
                    </div>
                    <div>
                      <div class="table">房号</div>
                      <div class="table">101</div>
                      <div class="table">2-101</div>
                    </div>
                  </div>
                </div>
                <el-button type="text" slot="reference" @click.stop="empty"
                  >excel格式</el-button
                >
              </el-popover>
            </el-upload>
          </el-form-item>
        </div>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="descr"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button style="margin-left: 30px" @click="sendShow = false"
          >取消</el-button
        >
        <el-button
          v-if="send_type == 'mobiles'"
          style="margin-left: 20px"
          type="primary"
          @click="generateCoupon"
          :loading="loading"
          >确认</el-button
        >
        <el-button
          v-if="send_type == 'excel'"
          style="margin-left: 20px"
          type="primary"
          :loading="loading"
          @click="importMemberExcel"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  props: {
    cooperator: {
      type: Object,
      default: () => {
        return {};
      },
    },
    cooperator_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      type: {
        cooperator: "合作商",
        property: "物业",
        commerce: "商会",
      },
      DataList: [],
      Option: [
        { name: "发卡方", value: "channels" },
        { name: "发卡类型", value: "coupon_type" },
        { name: "说明", value: "descr" },
        { name: "用户", value: "user", type: "custom" },
        { name: "发卡时间", value: "create_time" },
      ],
      Total: 0,
      sendShow: false,
      community_id: "",
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      fileData: "",
      send_type: "mobiles",
      loading: false,
      fileList: [],
      descr: "",
      memberList: [],
      change_mamber: "",
    };
  },

  mounted() {
    this.getMemberList();
  },

  methods: {
    getMemberList() {
      this.$get("user/member/card/queryListPage", { type: 1, status: 1 }).then(
        (res) => {
          this.memberList = res.data.data;
        }
      );
    },
    loadTemplate() {
      let Header = [["电话", "户主姓名", "区域", "楼号", "单元", "房号"]];
      let exportArr = [
        ["188**", "张三", "一区", "2", "2", "101", "注：没有的项可不填"],
      ];

      if(this.cooperator.type == 'commerce'){
         Header = [["电话", "姓名"]];
         exportArr = [
        ["188**", "张三"],
      ];
      }

      const headerWs = XLSX.utils.aoa_to_sheet(Header);
      const ws = XLSX.utils.sheet_add_json(headerWs, exportArr, {
        skipHeader: true,
        origin: "A2",
      });
      ws["!cols"] = [
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
        {
          wpx: 100,
        },
      ];
      /* 新建空的工作表 */
      const wb = XLSX.utils.book_new();

      // 可以自定义下载之后的sheetname
      XLSX.utils.book_append_sheet(wb, ws, "sheetName");

      /* 生成xlsx文件 */
      XLSX.writeFile(wb, "发卡信息模板.xlsx");
      this.$message("导出成功，请前往下载列表查看");
    },
    upLoad(file) {
      console.log(file);
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },

    importMemberExcel() {
      if (!this.fileData) {
        this.$message("请选择要导入的数据");
        return;
      }
      if (this.community_id == "" && this.cooperator.type != "commerce") {
        this.$message("请选择社区机构");
        return;
      }
      if (this.cooperator.type == "commerce" && !this.change_mamber) {
        this.$message("请选择会员卡");
        return;
      }
      let formDate = new FormData();
      formDate.append("file", this.fileData);
      formDate.append("id", this.change_mamber || 27);
      formDate.append(
        "user_id",
        JSON.parse(localStorage.getItem("managerInfo")).user_id
      );
      formDate.append("community_id", this.community_id);
      formDate.append("cooperator_id", this.cooperator_id);
      formDate.append("desc", this.descr || "");
      this.loading = true;
      this.$axios({
        url: "/user/commoditycoupon/importMemberPhones",
        data: formDate,
        method: "post",
        processData: false,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          this.getList(1);
          this.$message({ message: res.data.data });
          this.sendShow = false;
          this.loading = false;
          this.fileList = [];
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //合作商发卡
    generateCoupon() {
      if (this.dynamicTags.length == 0) {
        this.$message("请填写要发放的用户电话");
        return;
      }
      if (this.dynamicTags.length > 5) {
        this.$message("一次最多发放5个用户");
        return;
      }
      if (this.community_id == "" && this.cooperator.type != "commerce") {
        this.$message("请选择社区机构");
        return;
      }
        if (this.cooperator.type == "commerce" && !this.change_mamber) {
        this.$message("请选择会员卡");
        return;
      }
      let data = {
        number: this.dynamicTags.length,
        id: this.change_mamber || 27, //会员卡id
        coupon_length: 8,
        user_id: JSON.parse(localStorage.getItem("managerInfo")).user_id,
        duration: 30 * 60 * 24,
        channels: this.type[this.cooperator.type],
        type: "会员卡",
        mobiles: this.dynamicTags.join(","),
        community_id: this.community_id,
        cooperator_id: this.cooperator_id,
        descr: this.descr,
      };
      this.loading = true;
      this.$axios
        .post("/user/commoditycoupon/insertMemberListByPhones", data)
        .then((res) => {
          this.getList(1);
          this.$message({ message: res.data.message });
          this.sendShow = false;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    openSend() {
      this.sendShow = true;
      if (this.cooperator.type != "commerce") {
        this.$nextTick(() => {
          this.$refs.community.searchOrgName();
        });
      }
    },
    load() {
      if (this.DataList.length == 0) {
        this.getList(1);
      }
    },
    details(row) {
      this.$router.push({ name: "cooperatorDetails", query: { id: row.id } });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/couponList/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          cooperator_id: this.cooperator_id,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    empty() {
      return;
    },
    // 文件选择回调
    beforeUpload(file) {
      this.fileData = file.raw;
      this.readExcel(); // 调用读取数据的方法
    },
    // 读取数据
    readExcel(e) {
      const files = this.fileData;

      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0]; //取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格内容
          console.log(ws);
        } catch (e) {
          return false;
        }
      };
    },
  },
};
</script>

<style lang="less" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
a:hover {
  color: #333;
}

.table {
  height: 30px;
  line-height: 30px;
  border: 1px solid #333;
  border-bottom: none;
  padding: 0 10px 0 2px;
}
</style>