<template>
  <div class="container">
    <div class="info" v-if="cooperator.id">
    <el-descriptions title="合作商信息">
      <el-descriptions-item label="合作商">{{
        cooperator.title
      }}</el-descriptions-item>
      <el-descriptions-item label="联系人">{{
        cooperator.name
      }}</el-descriptions-item>
      <el-descriptions-item label="电话">{{
        cooperator.phone
      }}</el-descriptions-item>
       <el-descriptions-item label="账户余额">￥<span style="font-weight:600;">{{
        cooperator.account 
      }}</span></el-descriptions-item>
      <el-descriptions-item label="类型">
        <el-tag
          >{{type[cooperator.type] || ''}}</el-tag
        >
      </el-descriptions-item>
      <el-descriptions-item label="地址">{{
        cooperator.address
      }}</el-descriptions-item>
      <el-descriptions-item label="介绍">
        {{ cooperator.introduction }}
      </el-descriptions-item>
      <el-descriptions-item label="介绍图片">
        <div class="flex" v-if="cooperator.introduction_img">
          <el-image
            v-for="(pic, i) in cooperator.introduction_img.split(',')"
            :key="i"
            style="width: 100px; height: 100px"
            :src="pic"
          ></el-image>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    </div>
  <div>
      <el-button  v-if="cooperator.type == 'commerce'"  size="small" type="success" @click="shareCouponPage"
          >券包分享</el-button
        >
  </div>

    <el-tabs v-model="activeName" @tab-click="handleClick" v-if="show">
      <el-tab-pane label="收益管理" name="income">
        <income ref="income" :cooperator_id="id"></income>
      </el-tab-pane>
      <el-tab-pane label="物业管理" name="property" v-if="cooperator.type == 'cooperator'">
        <property ref="property" :cooperator_id="id"></property>
      </el-tab-pane>
      <el-tab-pane label="机构管理" name="mechanism" v-if="cooperator.type == 'property'">
        <mechanism ref="mechanism" :cooperator_id="id"></mechanism>
      </el-tab-pane>
       <el-tab-pane label="门店管理" name="store" v-if="cooperator.type == 'commerce'">
        <storeList ref="store" :cooperator_id="id"></storeList>
      </el-tab-pane>
      <el-tab-pane label="发卡管理" name="sendMember" v-if="cooperator.type != 'commerce'"> 
        <sendMember ref="sendMember" :cooperator_id="id"  :cooperator="cooperator"></sendMember>
      </el-tab-pane>
       <el-tab-pane label="员工管理" name="storeStaff" v-if="cooperator.type == 'commerce'">
        <storeStaff ref="storeStaff"  :cooperator="cooperator"></storeStaff>
      </el-tab-pane>
        <el-tab-pane label="员工管理" name="staff" v-if="cooperator.type == 'property'">
        <staff ref="staff" :cooperator_id="id"></staff>
      </el-tab-pane>
      
    </el-tabs>

    <el-dialog
      title="二维码"
      width="40%"
      :visible.sync="qrdialogVisible"
    >
      <div class="flex jc-c">
        <img :src="codeimg" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrdialogVisible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import income from "./components/details/income.vue";
import mechanism from "./components/details/mechanism.vue";
import property from "./components/details/property.vue";
import sendMember from "./components/details/sendMember.vue";
import staff from './components/details/staff.vue';
import storeList from './components/details/storeList.vue';
import storeStaff from './components/details/storeStaff.vue';

export default {
  components: {
    income,
    mechanism,
    property,
    sendMember,
    staff,
    storeList,
    storeStaff
  },
  data() {
    return {
      id: "",
      cooperator: {},
      activeName: "income",
      show: false,
      type: {
        cooperator: "合作商",
        property: "物业",
        commerce:'商会'
      },
      qrdialogVisible:false,
      codeimg:'',
      manager: JSON.parse(localStorage.getItem('managerInfo'))
    };
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.id) {
        this.id = this.$route.query.id;
        this.getDetails();
      }
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetails();
  },

  methods: {
    getQrCode() {
      let data = {
        scene: `admin_id=${this.manager.id}&coop=${this.id}`,
        page: 'pages/activity/couponPackage/couponPackage',
      };
      this.$axios({
        url: "/user/user/generateQRCodeSportPic",
        data,
        method: "post",
      }).then((res) => {
         this.codeimg = res.data.data.img;
      });
    },
    shareCouponPage(){
        this.qrdialogVisible = true;
      this.getQrCode();
    },
    handleClick(tab) {
      this.$refs[tab.name].load();
    },
    getDetails() {
      this.show = true;
     this.$nextTick(_=>{
         this.$refs.income.load();
      });
      this.$axios({
        url: "/user/cooperator/findById",
        params: {
          id: this.id,
        },
      }).then((res) => {
        this.cooperator = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info{
    margin-bottom: 30px;
}
</style>